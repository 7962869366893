<template>
  <GraphPage />
</template>

<script>
import GraphPage from "@/components/organisms/HoldersGraph/GraphPage.vue";

export default {
  name: "Graph",
  components: {
    GraphPage,
  },
};
</script>
