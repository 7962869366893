<template>
  <svg
    viewBox="0 0 41 30"
    xml:space="preserve"
    fill-rule="evenodd"
    clip-rule="evenodd"
    stroke-linejoin="round"
    stroke-miterlimit="2"
    v-if="color"
  >
    <path
      d="M39.2 24.9c-.4 2.1-2.1 3.7-4.2 4-3.3.5-8.8 1.1-15 1.1-6.1 0-11.6-.6-15-1.1-2.1-.3-3.8-1.9-4.2-4C.4 22.6 0 19.2 0 15s.4-7.6.8-9.9C1.2 3 2.9 1.4 5 1.1 8.3.6 13.8 0 20 0c6.2 0 11.6.6 15 1.1 2.1.3 3.8 1.9 4.2 4 .4 2.3.9 5.7.9 9.9-.1 4.2-.5 7.6-.9 9.9Z"
      fill="#ff3d00"
      fill-rule="nonzero"
    />
    <path d="M16 22V8l12 7-12 7Z" fill="#fff" fill-rule="nonzero" />
  </svg>
  <svg
    viewBox="0 0 50 38"
    xml:space="preserve"
    fill-rule="evenodd"
    clip-rule="evenodd"
    stroke-linejoin="round"
    stroke-miterlimit="2"
    v-else
  >
    <path
      d="M48.6037749 6.380225c-.4616999-2.55094995-2.6634748-4.40935005-5.220225-4.98937505C39.5600251.579975 32.4814-.00005 24.8227499-.00005c-7.65284985 0-14.8439998.580025-18.67332485 1.39089995-2.55094995.580025-4.7585251 2.3201-5.220225 4.98937505C.4616999 9.28035 0 13.340525 0 18.56075c0 5.220225.4616999 9.2804 1.0417249 12.180525.46750015 2.55094995 2.66927505 4.40935005 5.220225 4.98937505C10.3221249 36.541525 17.2824249 37.12155 24.941075 37.12155c7.6586501 0 14.6189501-.580025 18.6791251-1.39089995 2.55094995-.580025 4.75272485-2.3201 5.220225-4.98937505.4616999-2.900125 1.0417249-7.0786251 1.16005-12.180525-.2366502-5.220225-.8166752-9.2804-1.3967002-12.180525ZM18.5608 26.6811V10.4404l14.15144995 8.12035L18.5608 26.6811Z"
      fill-rule="nonzero"
    />
  </svg>
</template>

<script>
export default {
  name: "YoutubeLogo",
  props: {
    color: Boolean,
  },
};
</script>
