<template>
  <svg viewBox="0 0 24 24">
    <path
      d="M12 0C5.376 0 0 5.376 0 12s5.376 12 12 12 12-5.376 12-12S18.624 0 12 0ZM2.4 12c0-.732.096-1.452.252-2.136L8.388 15.6v1.2c0 1.32 1.08 2.4 2.4 2.4v2.316C6.072 20.916 2.4 16.884 2.4 12Zm16.668 6.48c-.312-.972-1.2-1.68-2.28-1.68h-1.2v-3.6c0-.66-.54-1.2-1.2-1.2h-7.2V9.6h2.4c.66 0 1.2-.54 1.2-1.2V6h2.4c1.32 0 2.4-1.08 2.4-2.4v-.492C19.104 4.524 21.6 7.98 21.6 12c0 2.496-.972 4.776-2.532 6.48Z"
    />
  </svg>
</template>

<script>
export default { name: "WebsiteIcon" };
</script>
