<template>
  <svg
    viewBox="0 0 248 243"
    xml:space="preserve"
    fill-rule="evenodd"
    clip-rule="evenodd"
    stroke-linejoin="round"
    stroke-miterlimit="2"
  >
    <path
      d="m81 84 44 45 42-45 81 158-248 1L81 84Zm4 33 40 40 38-40 53 107H32l53-107ZM123 0l37 72-35 38-37-38 35-72Zm0 42 14 29-13 11-12-11 11-29Z"
    />
  </svg>
</template>

<script>
export default { name: "VulkaniaLogo" };
</script>
