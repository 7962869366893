<template>
  <svg
    viewBox="0 0 19 19"
    xml:space="preserve"
    fill-rule="evenodd"
    clip-rule="evenodd"
    stroke-linejoin="round"
    stroke-miterlimit="2"
  >
    <path
      d="M16.62404 10.31204c0-.172-.14-.312-.312-.312h-2c-.172 0-.312.14-.312.312v5.688h-11.376v-11.376h5.688c.172 0 .312-.14.312-.312v-2c0-.172-.14-.312-.312-.312h-6c-1.277 0-2.312 1.035-2.312 2.312v12c0 1.277 1.035 2.312 2.312 2.312h12c1.277 0 2.312-1.035 2.312-2.312v-6Zm-6.312-10.312c-.172 0-.312.14-.312.312v2c0 .172.14.312.312.312h3.833l-7.261 7.26c-.122.122-.122.32 0 .442l1.414 1.414c.122.122.32.122.442 0l7.26-7.261v3.833c0 .172.14.312.312.312h2c.172 0 .312-.14.312-.312v-8c0-.172-.14-.312-.312-.312h-8Z"
      fill-rule="nonzero"
    />
  </svg>
</template>

<script>
export default { name: "OutwardsIcon" };
</script>
