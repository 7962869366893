/**
 * Copy to clipboard the text passed
 * @param {String} text string to copy
 */
export const clipboard = function (text) {
  const el = document.createElement("textarea");

  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};
