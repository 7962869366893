<template>
  <svg viewBox="0 0 525.916 525.916">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M276.879,0c0.443,0.144,0.88,0.385,1.33,0.416
  		c16.871,1.167,33.521,3.669,49.911,7.909c24.843,6.426,48.296,16.172,70.251,29.428c16.93,10.221,32.537,22.208,46.767,35.974
  		c29.089,28.141,50.416,61.299,64.596,99.172c3.046,8.136,4.404,16.221,2.364,24.751c-1.357,5.677-4.022,10.671-7.596,15.249
  		c-17.803,22.812-38.233,43.039-60.612,61.31c-8.676,7.085-17.645,13.812-26.486,20.693c-0.802,0.624-1.649,1.189-2.968,2.134
  		c0-1.236,0-2.015,0-2.793c0-51.365-0.018-102.73,0.022-154.096c0.005-6.584-1.642-12.458-6.262-17.324
  		c-4.002-4.216-8.956-6.707-14.691-6.801c-13.337-0.22-26.685-0.304-40.019,0c-11.761,0.268-20.939,10.374-20.943,22.485
  		c-0.017,57.166-0.008,114.332-0.008,171.498c0,6.96,0.063,13.922-0.018,20.881c-0.093,7.892-3.764,13.754-10.819,17.133
  		c-5.851,2.802-11.961,5.063-17.961,7.556c-0.224,0.093-0.485,0.095-1.012,0.191c0-0.926,0-1.731,0-2.537
  		c0-52.591-0.008-105.18,0.006-157.77c0.003-7.979-2.782-14.593-9.307-19.398c-3.966-2.922-8.475-4.242-13.392-4.238
  		c-12.181,0.009-24.362-0.072-36.542,0.024c-11.197,0.088-19.957,7.108-22.261,17.764c-0.417,1.927-0.426,3.968-0.426,5.957
  		c-0.021,57.166-0.032,114.332,0.002,171.498c0.004,7.087-2.513,12.816-8.441,16.823c-1.469,0.993-3.151,1.815-4.854,2.291
  		c-5.379,1.502-10.814,2.802-16.526,4.257c0-0.903,0-1.638,0-2.373c0-45.629-0.001-91.259,0.001-136.888
  		c0-7.503-2.531-13.86-8.467-18.659c-4.285-3.464-9.259-4.934-14.711-4.949c-11.988-0.033-23.975-0.115-35.962-0.079
  		c-13.166,0.041-22.792,9.748-22.794,22.906c-0.009,45.823-0.012,91.646,0.011,137.469c0.002,4.64-0.93,8.916-3.849,12.664
  		c-3.396,4.361-7.845,6.799-13.284,7.394c-8.708,0.952-17.407,2.154-26.143,2.631c-13.061,0.711-23.668-4.39-30.565-15.598
  		c-10.265-16.681-17.761-34.668-23.63-53.334c-5.797-18.438-9.425-37.301-11.053-56.555c-0.111-1.316-0.355-2.621-0.538-3.932
  		c0-12.245,0-24.491,0-36.736c0.164-1.008,0.353-2.013,0.487-3.025c1.168-8.806,1.987-17.675,3.557-26.409
  		c3.369-18.74,8.863-36.889,16.348-54.411c17.895-41.894,44.693-76.97,80.597-105.046c23.34-18.252,49.146-31.944,77.15-41.532
  		c22.616-7.743,45.888-12.138,69.735-13.574c0.394-0.024,0.775-0.262,1.162-0.399C258.317,0,267.599,0,276.879,0z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M252.13,525.916c-0.595-0.211-1.183-0.582-1.786-0.61
  		c-16.323-0.746-32.447-3.038-48.309-6.858c-19.326-4.655-38.007-11.222-55.806-20.173c-12.679-6.376-24.849-13.597-36.382-21.877
  		c-0.3-0.215-0.562-0.484-1.205-1.045c1.535-0.322,2.718-0.603,3.912-0.817c15.328-2.754,30.706-5.257,45.976-8.298
  		c29.068-5.792,57.56-13.807,85.688-23.114c29.864-9.883,58.943-21.719,87.15-35.638c29.158-14.389,57.106-30.851,83.41-50.002
  		c25.427-18.512,49.053-39.092,70.407-62.224c14.178-15.358,27.209-31.633,38.948-48.936c0.427-0.627,1.18-1.032,1.781-1.541
  		c0,12.116,0,24.233,0,36.35c-0.169,0.438-0.453,0.866-0.488,1.314c-1.206,15.507-3.782,30.784-7.67,45.839
  		c-7.393,28.624-19.347,55.234-35.683,79.842c-15.328,23.089-33.92,43.242-55.634,60.473
  		c-24.194,19.199-50.976,33.713-80.271,43.449c-23.706,7.88-48.072,12.425-73.082,13.304c-0.547,0.02-1.082,0.367-1.623,0.562
  		C265.021,525.916,258.576,525.916,252.13,525.916z"
    />
  </svg>
</template>

<script>
export default { name: "ScannerLogo" };
</script>
