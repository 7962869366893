<template>
  <svg viewBox="0 0 48 48">
    <path
      d="M24.05 24.6ZM4.85 42.35q-1.15 0-1.75-1-.6-1 0-2L22.3 6.3q.55-1.05 1.7-1.05t1.7 1.05l19.2 33.05q.6 1 0 2t-1.75 1Zm19.35-22.7q-.65 0-1.075.425-.425.425-.425 1.075v7.95q0 .65.425 1.075.425.425 1.075.425.65 0 1.075-.425.425-.425.425-1.075v-7.95q0-.65-.425-1.075-.425-.425-1.075-.425Zm0 16.45q.65 0 1.125-.475t.475-1.175q0-.65-.475-1.1-.475-.45-1.125-.45t-1.125.475q-.475.475-.475 1.125t.475 1.125q.475.475 1.125.475ZM7.75 38.7h32.5L24 10.6Z"
    />
  </svg>
</template>

<script>
export default { name: "WarningIcon" };
</script>
