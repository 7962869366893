<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 97.161 600 605.678">
    <path
      d="M469.4 307.255l20.82-115.458c7.571-49.211-30.284-94.637-79.495-94.637-43.533 0-79.495 35.962-79.495 79.495v98.423c-11.356-1.893-20.82-1.893-32.177-1.893s-22.713 1.893-34.069 1.893v-98.423c0-43.533-35.962-79.495-79.496-79.495-49.211 0-87.066 45.426-77.603 94.637l20.82 115.458C54.89 339.432 0 396.215 0 466.246v43.533c0 58.675 37.855 107.887 90.852 140.062 54.89 34.07 126.814 52.997 208.202 52.997s153.312-18.927 208.202-52.997C562.146 617.666 600 568.454 600 509.779v-43.533c0-70.031-56.782-126.814-130.6-158.991zM191.324 406.95c17.309 0 30.771 21.156 30.771 46.158s-13.462 46.156-30.771 46.156-30.771-21.154-30.771-46.156 13.462-46.158 30.771-46.158zm213.476 0c17.309 0 30.771 21.156 30.771 46.158s-13.463 46.156-30.771 46.156-30.771-21.154-30.771-46.156 13.462-46.158 30.771-46.158zM299.054 680.126c-151.419 0-274.448-75.71-274.448-168.454v-22.133c18.35 83.453 135.197 148.198 276.341 148.198 133.585 0 245.403-57.998 272.556-134.961v8.896c-.001 92.744-123.029 168.454-274.449 168.454z"
    />
  </svg>
</template>

<script>
export default { name: "PancakeswapLogo" };
</script>
