<template>
  <svg
    viewBox="0 0 495 343"
    xml:space="preserve"
    fill-rule="evenodd"
    clip-rule="evenodd"
    stroke-linejoin="round"
    stroke-miterlimit="2"
  >
    <path
      d="M127.513 342.326s13.65-17.877 27.435-33.605c-52.488-15.697-72.912-47.088-72.912-47.088s4.562 2.213 11.403 6.707h2.012c1.039 0 1.509.502 2.045 1.006v.202c.537.536 1.006 1.004 2.046 1.004 111.582 45.278 204.852 55.743 311.269-11.269 0 0-20.459 31.394-75.126 47.088 11.269 15.629 27.101 33.54 27.101 33.54 93.47-2.013 129.792-60.37 131.839-57.888 0-129.794-61.845-235.439-61.845-235.439C377.508 6.17 325.859 4.359 316.099 4.326l-6.237 6.137c70.598 20.124 104.772 51.549 104.772 51.549-184.057-90.588-335.952-2.951-339.441-2.214 0 0 34.108-33.605 109.334-51.548l-4.594-4.528S122.951 1.576 61.475 46.316c0 0-61.476 105.444-61.476 235.439.001 0 34.076 58.357 127.514 60.571Zm195.931-190.465v-.101c23.945 0 43.266 20.124 43.266 44.774 0 24.819-19.42 44.941-43.266 44.941-23.846 0-43.265-20.123-43.265-44.74.068-24.818 19.519-44.874 43.265-44.874Zm-154.88 0 .133-.101c23.846 0 43.131 20.124 43.131 44.774 0 24.819-19.418 44.941-43.264 44.941-23.847 0-43.265-20.123-43.265-44.74.001-24.818 19.419-44.874 43.265-44.874Z"
      fill-rule="nonzero"
    />
  </svg>
</template>

<script>
export default { name: "DiscordLogo" };
</script>
